@import 'styles/breakpoints.scss'

.container
  min-height: calc(100vh - 14rem) // 100vh - (4rem header + 10rem footer)
  margin-top: 4rem // 2rem + 4rem header
  padding-top: 2rem

@include media('>phone')
  .container
    margin-top: 3.5rem

@include media('>Ltablet')
  .container
    margin-top: 4rem
    padding-top: 4rem
